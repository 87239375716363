<template>
	<NavBar />
	<!-- Hero section starts -->
	<div class="container-lg mt-5">
		<div class="row justify-content-between mx-md-5">
		</div>
	</div>
	<!-- Hero section ends -->

	<div id="piano-subscription" class="mt-3"></div>

	<!-- CTA section starts -->
	<div class="container mt-md-5 mb-5" id="quiz">
		<div class="row justify-content-between mx-md-5">
			<div id="aboutUs" class="col-md-12">
			</div>
			
			<div id="subscriptionBenifits" class="col-md-12">
			</div>
			
		</div>
	</div>
	<!-- CTA section ends -->

	<!-- Info section starts -->
	<div class="container mx-md-auto px-md-4">
		<div class="info-container p-4 m-md-5">
			<div class="row">
				<div class="col-md-12 text-center">
					<a target="_blank" :href="publicPath + 'faq'">Have more questions? Read our FAQ</a>
				</div>
			</div>
		</div>
	</div>
	<!-- Info section ends -->

	<!-- Contact us section starts -->
	<div class="container mx-md-auto px-md-4">
		<div class="contact-us-container p-4 m-md-5">
			<div class="row">
				<div class="col-md-6 pt-2">
					<h2>Contact Us</h2>
					<p>For e-Paper subscription related queries and
						to know more about e-Paper web site
						and app features, please contact us.</p>
				</div>
				<div class="col-md-6 align-self-end align-content-end">
					<div class="float-md-end pe-3">
						<p><span><img class="me-2"
									:src="publicPath + 'icons/envelop.png'"></span>epapercare@thehindu.co.in</p>
						<p><span><img class="me-2" :src="publicPath + 'icons/phone.png'"></span>1800-102-1878</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Contact us section ends -->

	<!-- Readers editor content starts -->


	<div class="container mt-md-5 mb-5" id="quiz">
		<div class="row justify-content-between mx-md-5">
			<div id="readersEditor" class="col-md-12">

			</div>
		</div>
	</div>
	<!-- Readers editor content ends -->

	<!-- Footer starts -->
	<div class="container mx-md-auto px-md-4">
		<div class="p-4 m-md-5">
			<div class="row">
				<div class="col-12 text-center mb-2">
					<div class="publication-link">
						Explore Our e-Paper Family :
						<a href="https://epaper.thehindu.com/">The Hindu</a>&nbsp; &nbsp;
						<a href="https://epaper.thehindutamil.in/">The Hindu Tamil</a>&nbsp; &nbsp;
						<a href="https://eschool.thehindu.com/">The Hindu Inschool</a>
					</div>
				</div>
				<div class="col-12 text-center">
					<p class="copyright">Copyright © {{new Date().getFullYear()}}, The Hindu Group Publishing Pvt Ltd.</p>
				</div>
			</div>
		</div>
	</div>
	<!-- Footer ends -->
</template>

<script>
import NavBar from '@/components/LoginPage/NavBar.vue'

export default {
	data() {
		return {
			FreeTrailDays: process.env.VUE_APP_FREE,
			appName: process.env.VUE_APP_NAME,
			showAppIcons: process.env.VUE_APP_SHOW_APP_ICONS,
			publicPath: process.env.BASE_URL,
			logoPath: process.env.BASE_URL + process.env.VUE_APP_LOGO_FILE,
			playstoreIcon: './icons/play_store.png',
			appstoreIcon: './icons/app_store.png',
			laptopScreen: process.env.BASE_URL + process.env.VUE_APP_LOGO_FILE,
			mobileScreen: './icons/mobile_screen.png',
			features: [
				{
					icon: './icons/benefits/1.png',
					title: 'Listen, not just read',
					teaser: 'Read aloud gives you a hands-free experience of reading the newspaper.'
				},
				{
					icon: './icons/benefits/2.png',
					title: 'Flexible reading',
					teaser: 'Different views to match different tastes. Adjust layouts and views, use dark mode and change font size.',
				},
				{
					icon: './icons/benefits/3.png',
					title: 'Save what you like',
					teaser: 'Bookmark articles you need for easy access later or download them to read offline.',
				},
				{
					icon: './icons/benefits/4.png',
					title: 'Find what you are looking for',
					teaser: 'Use the search to jump to topics or articles you are interested in.'
				}
			],
		}
	},
	components: {
		NavBar
	},
	mounted() {
		window.tp?.push(["init", () => {
			window.tp?.experience.execute()
			if (window.tp.pianoId?.isUserValid()) {
				this.$router.push('/reader')
			}
		}]);
	}
}
</script>

<style scoped>
a {
	color: inherit;
}
.base-btn {
	padding: 13px 15px;
	border-radius: 16px;
	font-size: 16px;
	font-weight: bold;
	font-family: "Fira Sans", sans-serif !important;
	width: 210px;
}

.base-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white;
	padding: 20px;
	border-radius: 12px;
}

.cta-wrapper {
	align-items: center;
}

.welcome-wrapper {
	align-items: start;
	padding: 20px 58px;
}

.welcome-head-text {
	font-family: "Merriweather", serif;
	font-style: normal;
	font-weight: 700;
	font-size: 33px;
	color: rgba(40, 40, 40, 1);
	line-height: 1.3em;
	margin: 0;
}

.greeting-text {
	font-family: "Fira Sans", sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 21px;
	color: rgba(40, 40, 40, 0.53);
	line-height: 1.1em;
}

.cta-1 {
	background-color: #febf27;
	color: black;
}

.subscribe-cta {
	background-color: #153c59;
	color: white;
}

.cta-text-1 {
	font-family: "Merriweather", serif;
	font-style: normal;
	font-weight: 500;
	font-size: 13.6px;
}

.cta-text-2 {
	font-family: "Fira Sans", sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 13px;
}

.cta-text-1,
.cta-text-2 {
	margin-bottom: 20px;
}

.logo img {
	width: 150px;
}

.app-download-icons img {
	width: 155px;
}

/* Features section */

.features-wrapper,
.features-items {
	display: flex;
	justify-content: space-evenly;
	background-color: white;
}

.features-wrapper {
	padding: 20px;
	flex-grow: 0;
	border-radius: 12px;
}

.features-header {
	padding: 15px;
	text-align: left;
	font-family: "Merriweather", serif;
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	color: rgba(40, 40, 40, 0.75);
	line-height: 1.3em;
}

.feature {
	max-width: 205px;
	padding: 0 15px;
}

.feature .icon {
	margin-bottom: 10px;
	text-align: center;
}

.feature .title h5 {
	font-family: "Fira Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	color: rgba(40, 40, 40, 0.75);
}

.feature .teaser p {
	text-align: left;
	font-family: "Fira Sans", sans-serif;
	color: rgba(40, 40, 40, 0.5);
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	margin: 0;
}

.features-wrapper-mobile {
	display: none;
}

.accordion-button {
	padding: 5px;
	background-color: transparent;
	text-align: left;
	font-family: "Fira Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	color: rgba(40, 40, 40, 1);
	line-height: 1.3;
}

.accordion-body {
	padding: 0 5px;
	text-align: left;
	font-family: "Fira Sans", sans-serif;
	color: rgba(40, 40, 40, 1);
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.accordion-button:focus {
	border: none !important;
	box-shadow: none;
}

.accordion-button:not(.collapsed) {
	box-shadow: none;
}

.accordion-button::before {
	flex-shrink: 0;
	margin-right: 1rem;
	content: "";
	background-repeat: no-repeat;
	background-size: 1.25rem;
	transition: transform 0.2s ease-in-out;
	border-top: 6px solid #000;
	border-right: 6px solid transparent;
	border-bottom: 6px solid transparent;
	border-left: 6px solid transparent;
	right: 4px;
	top: calc(50% - 3px);
}

.feature-icon {
	width: 40%;
	text-align: center;
}

.feature-icon img {
	max-height: 20px;
}

button:not(.collapsed)::before {
	transform: rotate(-90deg);
	border-top: 6px solid #000;
	border-right: 6px solid transparent;
	border-bottom: 6px solid transparent;
	border-left: 6px solid transparent;
	right: 4px;
	top: calc(50% - 3px);
}

.accordion-button::after {
	content: none;
}

.info-cta-wrapper {
	background-color: #0973a3;
	border-radius: 12px;
	padding: 20px;
}

.info-cta-loader {
	display: flex;
	justify-content: center;
	padding: 40px;
}

.info-cta-wrapper h2 {
	font-family: "Merriweather", serif;
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	color: rgba(255, 255, 255, 1);
	margin-bottom: 10px;
	padding-top: 5px;
}

.info-cta {
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* padding: 5px 10px; */
}

.info-cta p {
	width: 180px;
	line-height: 20px;
	text-align: left;
	font-family: "Fira Sans", sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	color: rgba(255, 255, 255, 1);
	margin: 0;
}

.info-cta button {
	margin: 10px 0;
}

.info-container {
	background-color: rgba(246, 247, 252, 1);
	border: 2px solid rgba(9, 115, 163, 1);
	border-radius: 10px;
}

.info-container a {
	color: rgba(40, 40, 40, .6);
	font-weight: 500;
}

.contact-us-container {
	background-color: white;
	border-radius: 10px;
}

.publication-link,
.copyright {
	font-size: 14px;
	color: rgba(40, 40, 40, .6);
}

.publication-link a,
.copyright a {
	color: rgba(40, 40, 40, .6);
}

.welcome-images img {
	width: 70%;
}

.welcome-images {
	margin-top: 20px;
	padding: 50px;
}

@media only screen and (max-width: 1400px) {
	.welcome-head-text {
		font-size: 27px;
	}

	.welcome-images img {
		width: 70%;
	}

	.greeting-text {
		font-size: 18px;
	}

	.base-btn {
		font-size: 12px;
	}

	.cta-text-1,
	.cta-text-2 {
		font-size: 10px;
		margin-bottom: 10px;
	}
}

@media only screen and (max-width: 1280px) {
	.welcome-head-text {
		font-size: 24px;
	}

	.app-download-icons img {
		width: 108px;
		margin: 0;
		padding: 0;
	}
}

@media only screen and (max-width: 1200px) {
	.welcome-head-text {
		font-size: 20px;
	}

	.greeting-text {
		font-size: 13px;
	}

	.features-header {
		font-size: 18px;
	}

	.feature .title h5 {
		font-size: 15px;
	}

	.feature .teaser p {
		font-size: 12px;
	}
}

@media only screen and (max-width: 935px) {
	.welcome-wrapper {
		padding: 20px 20px;
	}
}

@media only screen and (max-width: 845px) {
	.features-header {
		font-size: 15px;
	}

	.feature .title h5 {
		font-size: 13px;
	}

	.feature .teaser p {
		font-size: 11px;
	}
}

@media only screen and (max-width: 767px) {
	.welcome-wrapper {
		padding: 20px 40px;
	}

	.features-wrapper {
		display: none;
	}

	.welcome-head-text {
		font-size: 16px;
	}

	.greeting-text {
		font-size: 11px;
	}

	.base-wrapper {
		margin-top: 20px;
	}

	.info-cta {
		display: block;
		text-align: center;
	}

	.info-cta-wrapper {
		margin-top: 20px;
	}

	.info-cta-wrapper h2 {
		text-align: center;
	}

	.info-cta p {
		width: 100%;
		text-align: center;
	}

	.features-wrapper-mobile {
		display: block;
	}
}

@media only screen and (max-width: 767px) {
	.welcome-images {
		margin-top: 0;
		padding: 28px;
	}
}
</style>