import Logger from "@/utils/logToConsole";

const authModule = {
    namespaced: true,
    state: () => ({
        authStatus: window.tp?.pianoId?.isUserValid() ?? false,
        userType: 'UNKNOWN'
    }),
    mutations: {
        updateAuthStatus: (state) => state.authStatus = window.tp?.pianoId?.isUserValid()
    },
    getters: {
        getAuthState: (state) => state.authStatus,
        getUserType: state => state.userType
    },
    actions: {
        login: (context) => {
            window.tp.pianoId.show({
                loggedIn: (data) => {
                    context.commit('updateAuthStatus')
                    Logger.debug("user ", data.user, " logged in with token", data.token);
                }
            });
        },
        logout: (context) => {
            window.tp.pianoId.logout()
            context.commit('updateAuthStatus')
        }
    }
}

export default authModule