import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import getTodaysDate from '@/utils/getTodaysDate'

const readerModule = {
    namespaced: true,
    state: () => ({
        loading: false,
        iframeUrl: "",
        date: getTodaysDate(),
        region: '',
        edition: '',
        regionsList: [],
        editionsList: [],
        currentArticeId: '',
        favouriteArticles: null
    }),
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default readerModule