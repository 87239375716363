<template>

    <div id="subscription"></div>
</template>


<script>
export default {
    mounted() {
        window.tp?.push(["init", () => {
            window.tp?.experience.execute()
        }]);
    }
}
</script>