import Logger from "@/utils/logToConsole"

const getPreferredEdition = (data) => {
    let preferredEdition = null
    if (data.custom_field_values) {
        const customFieldValues = data.custom_field_values
        const preferredEditionField = customFieldValues.filter(
            el => el.field_name === 'thg_edition'
        )
        if (Array.isArray(preferredEditionField) && preferredEditionField.length > 0) {
            try {
                const preferredEditionValue = JSON.parse(preferredEditionField[0].value)
                Logger.info(" preferredEditionValue ")
                Logger.info(preferredEditionValue)
                preferredEdition = preferredEditionValue
            } catch (err) {
                Logger.error("Get preferredEditionValue error: ")
                Logger.error(err)
            }
        } else Logger.error("Get preferredEditionValue error: No preferred edition set...")
    } else Logger.error("Get preferredEditionValue error: No custom field values present...")

    if (typeof preferredEdition === 'string') {
        if (preferredEdition.trim() !== "")
            return {
                id: process.env.VUE_APP_PUB + "_" + preferredEdition.toLowerCase(),
                title: preferredEdition
            }
    }

    return { id: process.env.VUE_APP_PUB + "_" + "chennai", title: "Chennai" }
}

export default getPreferredEdition