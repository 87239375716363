<template>
    <side-bar-right @route-event="routeTo($event)" />
    <side-bar-left :menu-items="leftSidebarMenus" />
    <the-header></the-header>

    <div class="loader d-flex justify-content-center align-items-center" v-if="isLoading">
        <div class="spinner-border" role="status"></div>
    </div>
    <iframe
        id="reader-iframe"
        v-if="isIframeUrlNotNull"
        :src="iframeUrl"
        frameborder="0"
        @load="registerIframeOnLoadActions"
    ></iframe>
    <div class="p-3" v-else>No issues available for this date: {{ date }}</div>
    <div id="curtain-raiser"></div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'

import TheHeader from '@/components/ReaderPage/MainNavBar.vue'
import SidebarProfile from '@/components/ReaderPage/sidebarMenus/SidebarProfile.vue'
import SidebarMenu from '@/components/ReaderPage/sidebarMenus/SidebarMenu.vue'
import Events from '@/utils/events'
import CallBacks from '@/utils/eventCallBacks'

import LeftSideBarMenuItems from '@/utils/leftSidebarMenuItems'

export default {
    components: {
        SideBarRight: SidebarProfile,
        TheHeader,
        SideBarLeft: SidebarMenu
    },
    data() {
        return {
            leftSidebarMenus: LeftSideBarMenuItems
        }
    },
    computed: {
        ...mapGetters('reader', {
            isLoading: 'getLoadingState',
            iframeUrl: 'getIframeUrl',
            date: 'getDate'
        }),
        isIframeUrlNotNull() {
            this.changeLoadingState(true)
            if (this.iframeUrl === 'null') this.changeLoadingState(false)
            return this.iframeUrl !== 'null'
        },
    },
    methods: {
        ...mapActions('reader',[
            'setInitialIframeUrl',
            'changeLoadingState'
        ]),
        routeTo(val) {
            window.$('#myaccountBtn').click()
            this.$router.push(`/${val}`)
        },
        registerIframeOnLoadActions(ev) {
            this.setIframeHeight()
            try {
                Events.forEach(eventID => this._registerEventListener(ev.target, eventID));
            } catch (error) {
                this.$logger.error("Error from eventListeners: ", error)
                this.changeLoadingState(false)
            }
        },
        /**
         * Pre-calculate iFrame height, to
         * 
         *      1. Avoid text blurring while viewing PDF.
         *      2. To avoid unwanted scrolling issues.
         */
        setIframeHeight() {
            const navbarHeight = document.getElementById('nav-bar').clientHeight
            const offset = 6
            const requiredHeight = innerHeight - navbarHeight - offset
            document.getElementById("reader-iframe").style.height = `${requiredHeight}px`
        },
        /**
         * Register eventListeners for all events in the [Events] array
         * 
         * @param HTMLElement target element to attach the listener
         * @param String eventId to listen for
         */
        _registerEventListener(target, eventID) {
            // Register event listener for the [eventID]
            target.contentWindow.addEventListener(eventID, eventDetails => {
                // Log recieved event details...
                this.$logger.debug(`${eventDetails.type} event occured...`)
                this.$logger.debug(eventDetails)
                // Register the respective callback for that [eventID]
                const cb = CallBacks[eventID]
                // Execute the callback with [store] and [eventDetails] as params
                if (cb) cb(this.$store, eventDetails)
                // GTM Event tracking
                CallBacks["common_event_tracking"](eventDetails)
            })
        }
    },
    created() {
        this.$logger.info("ReaderPage component created..")
        this.setInitialIframeUrl()
    },
    mounted() {
        this.setIframeHeight()
    }
}
</script>

<style>
/* .reader {
    width: 100%;
    height: 100vh;
    background-color: lightgray;
    position: relative;
    overflow: hidden;
} */
#reader-iframe {
    width: 100%;
    overflow: hidden;
}
.loader {
    height: 90vh;
}
</style>