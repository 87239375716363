<template>
    <the-header></the-header>
    <page-info-nav heading="Favourites"></page-info-nav>
    <div class="container mt-5 px-4 pt-4 pb-3 bg-white">
        <!-- <div class="card-view">
            <div class="row" v-for="article, index in articles" :key="index">
                <div class="col-lg-7 pt-4 pt-lg-0">
                    <a :href="article.articleURL"><h4>{{ article.headline }}</h4></a>
                    <img width="120" :src="article.imageURL ?? `${publicPath}placeholder.png`" class="float-end imgshadow ps-1" alt="">
                    <p class="fs-6"> {{ article.content }} </p>
                </div>
                <div class="d-flex justify-content-between p-2 px-3">
                    <div>{{ article.date }}</div>
                    <div>{{ article.edition }}</div>
                    <div><a href="#" @click.prevent="removeFavouritesOfUser(article.id)">Delete</a></div>
                </div>
            </div>
        </div> -->
        <table class="table"  id="pagination">
            <thead>
                <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Date</th>
                    <th scope="col" class="hide">Edition</th>
                    <th scope="col" class="no-sort hide">Images</th>
                    <th scope="col" class="no-sort hide">Summary</th>
                    <th scope="col" class="no-sort">Delete</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="loading">
                    <td colspan="6" class="border-0 pt-5">
                        <div class="row justify-content-center" role="status">
                            <div class="spinner-border"></div>
                        </div>
                    </td>
                </tr>
                <tr v-else-if="articles.length === 0">
                    <td colspan="6" class="border-0 pt-5">
                        <div class="row justify-content-center" role="status">
                            Nothing found
                        </div>
                    </td>
                </tr>
                <tr v-for="article, index in articles" :key="index">
                    <td class="result-title">
                        <a :href="article.articleURL">{{ article.headline }}</a>
                    </td>
                    <td style="width:86px">{{ article.date }}</td>
                    <td class="hide">{{ formatEditionName(article.edition) }}</td>
                    <td class="hide"><img width="100" :src="article.imageURL ?? `${publicPath}placeholder.png`" alt=""></td>
                    <td class="hide">{{ article.content }}</td>
                    <td class ='delet'><a href="#" @click.prevent="removeFavouritesOfUser(article.id)" >Delete</a></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

import TheHeader from '@/components/commonHeader.vue'
import PageInfoNav from '@/components/PageInfoNav';
import { getFavouriteArticles, addOrRemoveFavourites } from '@/request-handlers/favourites';
import formatEditionTitle from '@/utils/formatEditionTitle';

export default {
    components: {
        TheHeader,
        PageInfoNav
    },
    data() {
        return {
            loading: false,
            publicPath: process.env.BASE_URL,
            articles: []     
        }
    },
    methods: {
        recreateDataTable1() {
            if (this.dt) this.dt.destroy()
            this.$nextTick(() => {
                window.$('#pagination1').DataTable({
                    "searching": true,
                    "lengthChange": false,
                    "bInfo": false,
                    columnDefs: [{
                        orderable: false,
                        targets: "no-sort"
                    }]
                })
            });
        },
        async getFavouritesOfUser() {
            const result = await getFavouriteArticles()
                .then(res => {
                    this.$logger.debug(res)
                    this.loading = false
                    return res
                })
                .catch(err => { 
                    this.$logger.error(err);
                    this.loading = false 
                    return [] 
                })
            this.articles = result
            this.recreateDataTable1()
        },
        async removeFavouritesOfUser(articleId) {
            const result = await addOrRemoveFavourites(articleId, "remove")
            if (result instanceof Object && result !== null && result.status) 
                this.articles = this.articles.filter(article => article.id !== articleId)
            else 
                this.$logger.debug("result is null or error occured");
        },
        formatEditionName(editionName) {
            return formatEditionTitle(editionName)
        }
    },
    created() {
        this.loading = true
        this.getFavouritesOfUser()
    },
    mounted() {
        window.tp?.push(["init", () => {
            window.tp?.experience.execute()
        }]);
    }
}

</script>

<style scoped>
.card-view {
    display:none;
}

.card-view a {
    color: #337ab7;
    text-decoration: none;
}

.result-title a {
  cursor: pointer;
  color: #337ab7;
  font-weight: 500;
}

.table{
    width: 100%;
    padding-right: 10%;
}

@media only screen and (max-width: 766px) {
    .table {
        overflow: hidden;
        display: block;
    }
    .card-view {
        display: none;
        /* display:block; */
    }
    .hide {
        display: none;
    }
}
</style>