<template>
    <div id="page-info-nav" class="container-fluid">
        <div class="row nav-head align-items-center">
            <div class="col-md-4 col-2 back-button">
                <i class="ms-md-5 ms-3 fa fa-chevron-left" aria-hidden="true" @click="goBack"></i>
            </div>
            <div class="col-md-4 col-8">
                <h1>{{ heading }}</h1>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        heading: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            lastPath: this.$router.options.history.state.back
        }
    },
    methods: {
        goBack() {
            const path = this.lastPath ? this.lastPath : '/'
            this.$router.push(path)
        }
    },
}

</script>

<style scoped>
.nav-head {
    padding: 9.5px;
    background-color: #000;
    color: white;
}

.nav-head h1 {
    text-align: center;
    margin-bottom: 0;
    font-weight: normal;
    font-size: 2.4em;
}

.fa {
    margin-top: 4.5px;
    font-size: 1.4em;
}

.back-button:hover {
    cursor: pointer;
}
</style>