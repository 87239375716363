const getDataLayerInfo = (data) => {
    return {
        "event": data.registration ? "signup_successful" : "login_successful",
        "data": {
            "email": data.params.email,
            "uid": data.params.uid,
            "platform": typeof window.thgpiplatform === "function" ?
                window.thgpiplatform() : null
        }
    }
}

const loginAction = () => {
    // redirect to url if present in query params
    let urlObject = new URL(window.location.href)
    if (urlObject.searchParams.get('redirecturl') !== null) {
        window.location.href = urlObject.searchParams.get('redirecturl')
    } else {
        location.reload()
    }
}

function pianoInit(callbacks) {
    const tp = window.tp || [];

    tp.push(["setSandbox", Boolean(process.env.VUE_APP_PIANO_DEBUG_MODE == 'true')]);

    tp.push(["addHandler", "loginSuccess", (data) => {
        if (data.source == "PIANOID") {
            window.dataLayer?.push(getDataLayerInfo(data));
            loginAction();
        }
    }]);

    tp.push(['addHandler', 'logout', () => location.reload()])

    // Composer callbacks
    window.th_ep.subscribed = callbacks.subscribedUsersAction
    window.th_ep.notSubscribed = callbacks.notSubscribedUsersAction
    window.th_ep.trialUsersAction = callbacks.trialUsersAction
}

export default pianoInit