<template>
    <div class="container-fluid nav-wrapper">
        <header class="container-lg">
            <nav>
                <div class="desktop-menu">
                    <div class="logo">
                        <img :src="logoPath" />
                    </div>
                    <div class="desktop-menu-link">
                        <a href="#piano-subscription">
                            <BaseButton
                                device-type="desktop"
                                :class-names="['subscribe-btn']"
                            >SUBSCRIBE
                            </BaseButton>
                        </a>
                        <BaseButton
                            device-type="desktop" 
                            :class-names="['login-btn']" 
                            @click="showLoginScreen"
                        >LOGIN
                        </BaseButton>
                    </div>
                </div>
                <div class="mobile-menu mt-2 mb-1">
                    <div class="mobile-menu-header">
                        <div class="logo">
                            <img :src="logoPath" />
                        </div>
                        <div class="mobile-menu-buttons">
                            <a href="#piano-subscription">
                                <BaseButton
                                    device-type="mobile" 
                                    :class-names="['subscribe-btn']" 
                                >SUBSCRIBE
                                </BaseButton>
                            </a>
                            <BaseButton
                                device-type="mobile"
                                :class-names="['login-btn']"
                                @click="showLoginScreen"
                            >LOGIN
                            </BaseButton>
                        </div>
                    </div>

                </div>
            </nav>
        </header>
    </div>
</template>

<script>

import BaseButton from './BaseButton.vue'

export default {
    data() {
        return {
            publicPath: process.env.BASE_URL,
            logoPath: process.env.BASE_URL + process.env.VUE_APP_LOGO_FILE,
            mobileMenus: [
             
            ],
            desktopMenus: [
                {
                    title: 'Play Quiz',
                    href: "https://learningcorner.epaper.thehindu.com/quiz?utm_source=epaper&utm_medium=website"
                },
                {
                    title: "The Learning Corner",
                    href: "https://learningcorner.epaper.thehindu.com/?utm_source=epaper&utm_medium=website"
                },
                {
                    title: 'Buy A Subscription',
                    href: "#piano-subscription",
                }
            ]
        }
    },
    components: {
        BaseButton
    },
    methods: {
        showLoginScreen() {
            this.$store.dispatch('auth/login')
        }
    }
}

</script>

<style scoped>
.nav-wrapper {
    background-color: white;
    width: 100%;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.desktop-menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.mobile-menu {
    display: none;
}

.logo img {
    width: 85%;

}

.desktop-menu-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-link-ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
}

.menu-link-a {
    color: black;
    padding: 5px 10px;
    text-decoration: none;
    font-family: "Fira Sans", sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 17px;
}

.menu-link-a:hover {
    background-color: rgba(252, 182, 45, 0.25);
    border-bottom: 1px solid #202020;
}

@media screen and (max-width: 768px) {
    .desktop-menu {
        display: none;
    }

    nav {
        padding-left: 15px;
        padding-right: 15px;
    }

    .nav-wrapper,
    .nav-wrapper>header {
        padding: 0;
    }

    .logo img {
        width: 160px;
        margin-right: 5px;
    }

    .mobile-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .mobile-menu-header {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .mobile-menu-links {
        display: flex;
        flex-grow: 0;
        justify-content: flex-end;
        margin-top: 10px;
    }

    .menu-link-a {
        font-size: 12px;
    }
}
</style>
