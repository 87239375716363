<template>
    <button :class="classes" @click="clickAction">
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: {
        deviceType: {
            type: String,
            required: true
        },
        classNames: {
            type: Array,
            required: true
        },
        clickAction: {
            type: Function,
            required: false
        }
    },
    computed: {
        classes() {
            if (this.deviceType == 'mobile') return ['base-btn', 'base-btn-mobile', ...this.classNames]
            return ['base-btn', ...this.classNames]
        }
    }
}
</script>

<style scoped>
.base-btn {
    padding: 5px 15px;
    font-size: 14px;
    font-weight: bold;
    font-family: "Fira Sans", sans-serif !important;
    border-radius: 10px;
    margin: 0 5px;
    color: #fff;
}
.base-btn-mobile {
    padding: 3px 10px;
    font-size: 12px;
    margin: 0 5px;
}

.login-btn {
    border: 1px solid #0973a3;
    background: #0973a3;
}

.subscribe-btn {
    border: 1px solid #153c59;
    background: #153c59;
}
</style>