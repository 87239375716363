<template>
    <the-header></the-header>
    <page-info-nav heading="Most Popular"></page-info-nav>
    <div class="container bg-white wrapper">
        <filter-menu></filter-menu>
        <div class="row px-3 mt-3">
            <div class="col-12 text-center p-3" v-if="isLoading">
                Loading...
            </div>
            <div class="col-12 text-center p-3" v-else-if="articles.length === 0">
                Nothing found
            </div>
            <div class="col-md-6 col-12" v-for="article, index in articles" :key="index">
                <article-card 
                    :date="article.date"
                    :imageURL="article.imageURL ?? `${publicPath}placeholder.png`"
                    :headline="article.headline" 
                    :content="article.content"
                    :articleURL="article.articleURL"
                    :editionTitle="formatEditionName(article.edition)"
                ></article-card>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'

import TheHeader from '@/components/commonHeader.vue'
import PageInfoNav from '@/components/PageInfoNav';
import FilterMenu from '@/components/MostPopularPage/FilterMenu'
import ArticleCard from '@/components/MostPopularPage/ArticleCard'
import formatEditionTitle from '@/utils/formatEditionTitle';

export default {
    components: {
        TheHeader,
        PageInfoNav,
        FilterMenu,
        ArticleCard
    },
    mounted() {
        window.tp?.push(["init", () => {
            window.tp?.experience.execute()
        }]);
    },
    data() {
        return {
            publicPath: process.env.BASE_URL
        }
    },
    computed: {
        ...mapGetters('activity', {
            articles: 'getArticles',
            isLoading: 'getLoadingState'
        })
    },
    methods: {
        formatEditionName(editionName) {
            return formatEditionTitle(editionName)
        }
    }
}

</script>

<style scoped>
.wrapper {
    padding: 5px 20px;
    margin-top: 40px;
}
</style>