const AppGuard = (to, _, next) => {
    document.title = to.meta.title ?? process.env.VUE_APP_META_TITLE
    const auth = window.tp?.pianoId?.isUserValid()
    if (!auth && to.meta.auth) next('/login')
    else if (auth && to.path === '/login') next('/reader')
    else next()
}

const WebViewGuard = (to, _, next) => {
    if (to.query.apptoken)
        try {
            window.tp.pianoId.loginByToken(to.query.apptoken)
            return next()
        } catch (error) {
            console.log(error);
        }
    return next()
}

export { AppGuard, WebViewGuard }