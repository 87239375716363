import { emitUserFavList, emitFavListResponse, emitUserType } from "./dependantEvents"
import { addOrRemoveFavourites, getFavouriteArticlesIds } from "@/request-handlers/favourites"
import Logger from "./logToConsole"

const MatomoTrackDetails = (ev, detail) => ['trackEvent', 'click', ev, detail, 1]

const PushMatomoEvents = (ev, detail) => {
    if (window._paq) {
        window._paq.push(
            MatomoTrackDetails(ev, detail)
        )
    }
}

const initFavouritesList = (store) => {
    if (store.state.reader.favouriteArticles === null) {
        Logger.debug("Favourites not loaded...")
        getFavouriteArticlesIds()
            .then(res => {
                if (res.data && Array.isArray(res.data)) {
                    emitUserFavList(res.data)
                    store.state.reader.favouriteArticles = res.data
                }
                else if (res.status && res.status === false) emitFavListResponse(false)
            })
            .catch(() => emitFavListResponse(false))
    } else emitUserFavList(store.state.reader.favouriteArticles)
}

const PagesView = (store, e) => {
    store.commit('reader/changeLoadingState', false)
    initFavouritesList(store)
    emitUserType(store)
    // Set current [page_layout_desk] in cookie
    if (e.detail.page_layout_desk) {
        document.cookie = "_pc_pageName=" + e.detail.page_layout_desk
        window.tp?.experience.execute()
    }
    // Push current [page_internal_name] to piano to block pageView
    if (e.detail.page_internal_name) {
        Logger.debug("tp custom variable pagename: " + e.detail.page_internal_name);
        window.tp?.push(["setCustomVariable", "pagename", e.detail.page_internal_name]);
    }
}

const ArticleView = (store, e) => {
    // Push [articleView] event to matomo
    if (e.detail.article_internal_id) {
        store.state.reader.currentArticeId = e.detail.article_internal_id
        PushMatomoEvents('articleView', e.detail.article_internal_id)
        window.tp.experience.execute()
    }

    // Push current [page_internal_name] to piano to block pageView
    if (e.detail.page_internal_name) {
        Logger.debug("[from articleViewEvent] tp custom variable pagename: " + e.detail.page_internal_name);
        window.tp?.push(["setCustomVariable", "pagename", e.detail.page_internal_name]);
    }
}

const FavAction = (store, e) => {
    const action = e.type === "remove_article_from_fav_internal" ? "remove" : "add"
    addOrRemoveFavourites(store.state.reader.currentArticeId, action)
        .then(res => {
            if (res.status && res.status === false) emitFavListResponse(false)
            else if (res.status) {
                if (action === "add") {
                    store.state.reader.favouriteArticles.push(
                        store.state.reader.currentArticeId
                    )
                }
                else if (action === "remove") {
                    const index = store.state.reader.favouriteArticles.indexOf(
                        store.state.reader.currentArticeId
                    );
                    if (index > -1)
                        store.state.reader.favouriteArticles.splice(index, 1);
                }
            }
        })
        .catch(() => emitFavListResponse(false))
}

const DownloadOrShareButtonClicked = (_, e) => {
    if (e.type === "article_print_button_click" && e.detail.issue_internal_id)
        PushMatomoEvents('articleDownload', e.detail.issue_internal_id)

    else if (e.type === "article_share_opened" && e.detail.article_internal_id)
        PushMatomoEvents('articleShare', e.detail.article_internal_id)

    else return
}

/** 
 * This function could be used to track all other events,
 * which are not tracked specifically.
 */
const CommonEventTracking = (e) => {
    const event_details = {
        "event": "reader_event",
        "data": { "event_name": e.type }
    }

    window.dataLayer?.push(event_details)
}

export default {
    'pages_page_view': PagesView,
    'article_article_view': ArticleView,
    'add_article_to_fav_internal': FavAction,
    'remove_article_from_fav_internal': FavAction,
    'article_print_button_click': DownloadOrShareButtonClicked,
    'article_share_opened': DownloadOrShareButtonClicked,
    'common_event_tracking': CommonEventTracking
}