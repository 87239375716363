<template>
    <the-header></the-header>
    <page-info-nav heading="My Profile"></page-info-nav>
    <div class="p-3" id="my-account"></div>
</template>

<script>

import TheHeader from '@/components/commonHeader.vue'
import PageInfoNav from '@/components/PageInfoNav';

export default {
    name: 'MyAccountPage',
    components: {
        TheHeader,
        PageInfoNav
    },
    mounted() {
        // window.tp.experience.execute();
        window.tp.pianoId.init({
            profileUpdate: () => {
                if (process.env.VUE_APP_ONLY_PROD === 'true') {
                    location.reload()
                }
            }
        });
        window.tp.myaccount.show({
            displayMode: "inline",
            containerSelector: "#my-account",
        });
    }
}

</script>

<style scoped>
body {
    background-color: #ffffff !important;
}
</style>