<template>
    <div class="p-3" id="free-trial-webview"></div>
</template>


<script>
export default {
    mounted() {
        window.tp?.push(["init", () => {
            window.tp?.experience.execute()
        }]);
    }
}
</script>

<style scoped>
body {
    background-color: #ffffff !important;
}
</style>