<template>
    <the-header></the-header>
    <page-info-nav heading="Terms and Conditions"></page-info-nav>
    <accordion-wrapper :id="parentId" class="container-lg mt-5 px-md-5">
        <accordion-item id="ac1" :parent-id="parentId" heading="1. User Agreement">
            The publishing business undertaking of Kasturi & Sons Limited has been demerged, transferred and vested with THG Publishing Private Limited, a subsidiary of Kasturi & Sons Limited, pursuant to the order of the National Company Law Tribunal (NCLT, Chennai Bench) sanctioning the Scheme of Arrangement (Demerger) (‘the Scheme’).
            In accordance with the Scheme, all the contracts/ agreements entered into by Kasturi & Sons Limited in relation to the publishing business undertaking and the related rights and obligations thereto, stand transferred in favour of THG Publishing Private Limited.
            You (the user) can access epaper.thehindubusinessline.com ("the site"), subject to the terms and conditions of use, as changed / updated from time to time entirely at the discretion of THG Publishing Private Limited as set out below. This Agreement is for an indefinite term and you understand and agree that are bound by such terms till the time you access this site.
        </accordion-item>
        <accordion-item
            id="ac2"
            :parent-id="parentId"
            heading="2. Changes"
        >We reserve the right to add to or change/modify the terms of this Agreement including but not limited to suspend / cancel, or discontinue any or all channels, or service at any time without notice, make modifications and alterations in any or all of the content, products and services contained on the site without prior notice.. You will be deemed to have accepted such change/s if you continue to access the Site subsequent to such changes.</accordion-item>
        <accordion-item id="ac3" :parent-id="parentId" heading="3. Registration">
            <br />When you register, you are registering as a personal user of epaper.thehindubusinessline.com.
            Access to registration areas is via your email address and password.
            <br />
            <br />We allow you access to the registration areas of the site on the basis that:
            <br />
            <br />(i) Your email address and password are personal to you and may
            not be used by anyone else to access epaper.thehindubusinessline.com and you
            have completed 18 (Eighteen) years of age. If not, your registration
            shall be subject to your Guardian approval for usage by you.
            <br />
            <br />(ii) You will not do anything which would assist anyone who is not a
            registered user to gain access to any registration area of epaper.thehindubusinessline.com
            <br />
            <br />(iii) You do not create additional registration accounts for the purpose
            of abusing the functionality of the site, or other users; nor do you
            seek to pass yourself off as another user
            <br />
            <br />(iv) You comply with these terms and conditions.
            <br />
            <br />If, for any reason, we believe that you have not complied with these requirements,
            we may, at our discretion, cancel your access to the registration areas of thehinduepaper.com
            or epaper.thehindubusinessline.com and without giving you any advance notice.
        </accordion-item>
        <accordion-item
            id="ac4"
            :parent-id="parentId"
            heading="4. Charges"
        >Any payment or subscription paid is non-refundable. We reserve the right to change subscription and / or membership fees any time without prior notice.</accordion-item>
        <accordion-item
            id="ac5"
            :parent-id="parentId"
            heading="5. Copyright and Trademarks"
        >Unless otherwise stated, copyright and all intellectual property rights in all material presented on the site (including but not limited to text, audio, video or graphical images), trademarks and logos appearing on this site are the property of THG Publishing Private Limited, its parent, affiliates and associates and are protected under applicable Indian laws. Any infringement shall be vigorously defended and pursued to the fullest extent permitted by law.</accordion-item>
        <accordion-item
            id="ac6"
            :parent-id="parentId"
            heading="6. Termination of Registration"
        >If we wish to bring the agreement to an end, we will do so by emailing you at the address you have registered stating that the agreement has terminated. The agreement will terminate and your email address and password will become invalid on epaper.thehindubusinessline.com immediately.</accordion-item>
        <accordion-item id="ac7" :parent-id="parentId" heading="7. Limited Permission to Copy">
            You are permitted to print or download extracts from these pages
            for your personal non-commercial use only. Any copies of these pages
            saved to disk or to any other storage medium may only be used for
            subsequent viewing purposes or to print extracts for personal use.
            You may not (whether directly or through the use of any software program)
            create a database in electronic or structured manual form by regularly or
            systematically downloading and storing all or any part of the pages
            from this site. No part of the Site may be reproduced or transmitted to
            or stored in any other web site, nor may any of its pages or part thereof
            be disseminated in any electronic or non-electronic form, nor included in
            any public or private electronic retrieval system or service without
            prior written permission.
            <p>
                <br />
                <strong>Third Party Material</strong>
                <br />
                <br />You will see advertising material submitted by third parties
                on epaper.thehindubusinessline.com Individual advertisers are solely
                responsible for the content of advertising material which
                they submit to us, including ensuring that it complies with
                relevant legislation. We accept no responsibility for the
                content of advertising material, including, without limitation,
                any error, omission or inaccuracy therein.
            </p>
        </accordion-item>
        <accordion-item id="ac8" :parent-id="parentId" heading="8. E-Mail Services">
            <p>You agree to not use the service to:</p>
            <p>
                Upload, post, email, transmit or otherwise make available any
                content that is unlawful, harmful, threatening, abusive, harassing,
                tortious, defamatory, vulgar, obscene, libellous, invasive of another's
                privacy, hateful, or racially, ethnically or otherwise objectionable.
            </p>
            <p>Harm minors in any way.</p>
            <p>
                Impersonate any person or entity, including, but not limited to
                THG Publishing Private Limited official, forum leader, guide or host, or
                falsely state or otherwise misrepresent your affiliation
                with a person or entity.
            </p>
            <p>
                Forge headers or otherwise manipulate identifiers in order to
                disguise the origin of any content transmitted through the service.
            </p>
            <p>
                Upload, post, email, transmit or otherwise make available any
                content that you do not have a right to make available under any law or
                under contractual or fiduciary relationships (such as inside
                information, proprietary and confidential information learned or
                disclosed as part of employment relationships or under nondisclosure
                agreements).
            </p>
            <p>
                Upload, post, email, transmit or otherwise make available any Content
                that infringes any patent, trademark, trade secret, copyright or other
                proprietary rights ("Rights") of any party.
            </p>
            <p>
                Upload, post, email, transmit or otherwise make available any unsolicited or
                unauthorised advertising, promotional materials, "junk mail," "spam," "chain letters,"
                "pyramid schemes," or any other form of solicitation
                <br />
            </p>
        </accordion-item>
        <accordion-item id="ac9" :parent-id="parentId" heading="9. Disclaimer">
            <p>
                All the contents of this Site are only for general information or use.
                They do not constitute advice and should not be relied upon in making
                (or refraining from making) any decision. Any specific advice or replies
                to queries in any part of the Site is/are the personal opinion of such
                experts/consultants/persons and are not subscribed to by this Site.
                The information from or through this site is provided on "AS IS" basis,
                and all warranties, expressed or implied of any kind, regarding any matter
                pertaining to any goods, service or channel, including without limitation,
                the implied warranties of merchantability, fitness for a particular purpose,
                and non-infringement are disclaimed and excluded.
            </p>
            <p>
                THG Publishing Private Limited shall not be liable, at any time for
                damages (including, without limitation, damages for loss of business
                projects, or loss of profits) arising in contract, tort or otherwise
                from the use of or inability to use the Site, or any of its contents, or
                from any act or omissions a result of using the Site or any such
                contents or for any failure of performance, error, omission,
                interruption, deletion, defect, delay in operation or transmission,
                computer virus, communications line failure, theft or destruction or
                unauthorised access to, alteration of, or use of information contained
                on the site. No representations, warranties or guarantees whatsoever are
                made as to the accuracy, adequacy, reliability, completeness, suitability
                or applicability of the information to a particular situation.
            </p>
            <p>
                Certain links on the Site lead to resources located on servers maintained by
                third parties over whom THG Publishing Private Limited has no control or connection,
                business or otherwise as these sites are external to THG Publishing Private Limited you
                agree and understand that by visiting such sites you are beyond the
                THG Publishing Private Limited website. THG Publishing Private Limited therefore neither endorses
                nor offers any judgement or warranty and accepts no responsibility or liability
                for the authenticity/availability of any of the goods/services/or for any damage,
                loss or harm, direct or consequential or any violation of local or international
                laws that may be incurred by your visit and/or transaction/s on these sites
            </p>
        </accordion-item>
        <accordion-item
            id="ac10"
            :parent-id="parentId"
            heading="10. Force Majuere"
        >Although we will do our best to provide constant, uninterrupted access to thehindubusinessline.com or epaper.thehindubusinessline.com, we do not guarantee this. We accept no responsibility or liability for any interruption or delay.</accordion-item>
        <accordion-item
            id="ac11"
            :parent-id="parentId"
            heading="11. Content Sharing"
        >The contents of this e-Paper are proprietary and should not be shared with anyone. This condition doesn’t apply to sharing individual articles on social media websites for the purpose of initiating discussions and expressing opinions.</accordion-item>
        <accordion-item
            id="ac12"
            :parent-id="parentId"
            heading="12. Governing Law"
        >This agreement shall be governed by the Indian Law.</accordion-item>
        <accordion-item
            id="ac13"
            :parent-id="parentId"
            heading="13. Jurisdiction"
        >The Courts in Chennai shall have the exclusive jurisdiction to the exclusion of other courts.</accordion-item>
    </accordion-wrapper>
</template>

<script>

import TheHeader from '@/components/commonHeader.vue'
import AccordionWrapper from '@/components/AccordionWrapper';
import AccordionItem from '@/components/AccordionItem';
import PageInfoNav from '@/components/PageInfoNav';

export default {
    components: {
        TheHeader,
        AccordionWrapper,
        AccordionItem,
        PageInfoNav
    },
    data() {
        return {
            parentId: "tc-accordion"
        }
    },
    mounted() {
        window.tp?.push(["init", () => {
            window.tp?.experience.execute()
        }]);
    }
}

</script>

<style scoped>
.col-sm-12 p {
    font-size: 14px;
}
.col-sm-12 a {
    text-decoration: none;
    color: #337ab7;
}
.col-sm-12 a:hover {
    text-decoration: underline;
    color: #2b71ad;
}
</style>