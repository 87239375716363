import getReaderUrl from '@/request-handlers/getReaderUrl';
// import getTodaysDate from '@/utils/getTodaysDate.js';
import getRegionList from '@/request-handlers/getRegionList';
import getEditionsList from '@/request-handlers/getEditionsList';
import FormatEditionTitle from '@/utils/formatEditionTitle';
import getPreferredEdition from '@/utils/getPreferredEdition';

export default {
    login: () => window.tp.pianoId.show({
        loggedIn: () => { }
    }),
    logout: () => window.tp.pianoId.logout(),
    changeLoadingState: (context, loadingState) => context.commit('changeLoadingState', loadingState),
    changeDate: (context, payload) => context.commit('changeDate', payload),
    changeRegion: (context, payload) => context.commit('changeRegion', payload),
    changeEdition: (context, payload) => context.commit('changeEdition', payload),
    updateRegionsList: async (context) => {
        const result = await getRegionList(context.state.date, { credentials: process.env.VUE_APP_INCLUDE_CREDENTIALS })
            .then(res => res.json()).catch(() => [])
        // Change the state.regionList
        if (result.publications && result.publications.length > 0) {
            const list = result.publications
                .filter(el => el.issues.web && el.issues.web.length > 0)
                .map(el => { return { id: el.id, title: FormatEditionTitle(el.title) } })
            context.commit('changeRegionList', list)
        }
    },
    updateEditionsList: async (context) => {
        const result = await getEditionsList(
            context.state.region.id,
            context.state.date,
            { credentials: process.env.VUE_APP_INCLUDE_CREDENTIALS }
        ).then(res => res.json()).catch(() => [])
        // Change the state.editionsList
        if (result.publications && result.publications.length > 0) {
            const list = result.publications
                .filter(el => el.issues.web && el.issues.web.length > 0)
                .map(el => {
                    return {
                        id: el.id,
                        title: FormatEditionTitle(el.title),
                        thumbnail: el.issues.web[0].coverImageThumbnailUri
                    }
                })
            context.commit('changeEditionsList', list)
        }
    },
    // change the selectedEdition as the first result from the jsonData
    updateSelectedEdition: (context) => context.commit('changeEdition', context.state.editionsList[0]),
    // change the selectedRegion as the first result from the jsonData
    updateSelectedRegion: (context) => context.commit('changeRegion', context.state.regionsList[0]),
    changeIframeUrl: async (context) => {
        context.commit('changeLoadingState', true)
        if (context.state.edition && context.state.edition.id) {
            const url = await getReaderUrl(
                context.state.edition.id,
                context.state.date,
                { credentials: process.env.VUE_APP_INCLUDE_CREDENTIALS }
            ).then(res => res.json()).then(res => {
                if (res.issues.web && res.issues.web.length > 0)
                    return res.issues.web[0].readerUrl
                return 'null'
            }).catch(() => 'null')
            context.commit('changeIframeUrl', url)
        } else context.commit('changeIframeUrl', 'null')
        context.commit('changeLoadingState', false)
    },
    setInitialIframeUrl: async (context) => {
        if (window.tp) {
            window.tp.pianoId.loadExtendedUser({
                extendedUserLoaded: (data) => {

                    // const date = getTodaysDate()
                    const preferredEditionData = getPreferredEdition(data)
                    const region = preferredEditionData
                    const edition = preferredEditionData

                    context.dispatch('changeRegion', region)
                    context.dispatch('changeEdition', edition)
                    context.dispatch('updateRegionsList')
                    context.dispatch('updateEditionsList')
                    context.dispatch('changeIframeUrl')
                },
                formName: "MyAccountFields"
            })
        }
    }

}