import getArticleDetailFromIDs from "./getArticleDetails"
import Logger from "@/utils/logToConsole"

const getFavouriteArticlesIds = async () => {
    const userDetails = window.tp.pianoId.getUser()
    const userId = userDetails ? userDetails.uid : null

    if (userId === null) return null

    const favEndPoint = process.env.VUE_APP_FAV_EP + `?userId=${userId}&pub=${process.env.VUE_APP_PUB}ep`

    const result = await fetch(favEndPoint, {
        method: 'GET',
        credentials: process.env.VUE_APP_INCLUDE_CREDENTIALS,
        headers: { 
            'Content-Type': 'application/json' ,
            'x-client-auth': window.tp.pianoId.getToken()
        }
    })
        .then(res => res.json())
        .catch(() => null)

    return result
}

const addOrRemoveFavourites = async (articleId = "", action = "add") => {
    const userDetails = window.tp.pianoId.getUser()
    const userId = userDetails ? userDetails.uid : null

    if (userId === null || articleId.trim() == "") return null

    const favEndPoint = process.env.VUE_APP_FAV_EP

    const result = await fetch(favEndPoint, {
        method: action === "add" ? 'POST' : 'DELETE',
        credentials: process.env.VUE_APP_INCLUDE_CREDENTIALS,
        headers: { 
            'Content-Type': 'application/json' ,
            'x-client-auth': window.tp.pianoId.getToken()
        },
        body: JSON.stringify({ "userId": userId, "articleId": articleId, "pub": `${process.env.VUE_APP_PUB}ep` }),
    })
        .then(res => res.json())
        .catch(() => null)

    return result
}

const getFavouriteArticles = async () => {
    const articleIds = await getFavouriteArticlesIds()
        .then(res => {
            if (res.data && Array.isArray(res.data)) return res.data
            return []
        })
        .catch(err => { Logger.error(err); return [] })
    const articles = await getArticleDetailFromIDs(articleIds)
    return articles
}

export { getFavouriteArticlesIds, getFavouriteArticles, addOrRemoveFavourites }