const renamemap = {
    "bangalore":"Bengaluru",
    "mangalore" : "Mangaluru",
    "hubli" : "Hubballi"
}
export default (title) => {
    const splitEditionTitle = title.split('-')
    const testEpaperStringIndex = splitEditionTitle.indexOf('EPaper')
    if (testEpaperStringIndex !== -1) splitEditionTitle.splice(testEpaperStringIndex, 1)
    const maintitlelist = splitEditionTitle.length == 1 ? splitEditionTitle[0] : splitEditionTitle.join('-')
    const regionchange = renamemap[maintitlelist?.toLowerCase()] ?? maintitlelist ; 
    return regionchange
}